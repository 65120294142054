.container {
  position: relative;
  filter: blur(2px);
  overflow: hidden !important;
  backdrop-filter: blur(10px);
}

.overLay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1021;
  background-color: rgba(0, 0, 0, 0.515);
}

.mainCard {
  width: 60vw;
  margin: auto;
  position: fixed;
  top: calc(100vh - 80vh);
  right: 0;
  left: 0;
  z-index: 1022;
}

.submitBtn {
  background: linear-gradient(90deg, #1e3c70 0%, #970000 100%);
}

.col1 {
  background: linear-gradient(180deg, #1e3c70 0%, #970000 100%);
}

.caLink {
  font-size: 12px;
  margin-top: 0.5rem;
}

.indicators {
  bottom: -45px;
}

@media only screen and (max-width: 576px) {
  .mainCard {
    width: 90%;
  }
}
